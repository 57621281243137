import { LinkProps } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      defaultBG: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      defaultBG?: string;
    };
  }
}

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;

  const location = useLocation();
  let finalTo = href;
  if (location.search.includes("translationsEnabled=true")) {
    finalTo = `${href}?translationsEnabled=true`;
  }
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={finalTo} {...other} />
  );
});
LinkBehavior.displayName = "LinkBehavior";

const theme = createTheme({
  palette: {
    background: {
      paper: "#F9FAFB",
    },
    primary: {
      main: "#1355FF",
    },
  },
  status: {
    defaultBG: "#F9FAFB",
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    body1: {
      color: "black",
      lineHeight: 1.75,
    },
    body2: {
      color: "#586174",
      lineHeight: 1.75,
    },
    h1: {
      fontWeight: 600,
      fontSize: "3.5rem",
    },
    h2: {
      fontWeight: 600,
      fontSize: "2.5rem",
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: 600,
      fontSize: "1rem",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: "0 2rem",
          "@media (min-width: 600px)": {
            padding: "0 2rem",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          borderRadius: "6px",
          padding: "1.5rem",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          fontSize: ".875rem",
          textDecoration: "none",
          color: "#1B114A",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "400",
          textTransform: "capitalize",
          padding: ".4rem 1.25rem",
          borderRadius: "6px",
        },
        outlined: (props) => {
          const color = props.ownerState.color;
          if (color && color !== "inherit") {
            // Set to a semi transparent border
            return {
              border: `1px solid ${props.theme.palette[color].main}33`,
            };
          }
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
