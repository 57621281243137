import useTranslation from "@cospex/client/hooks/useTranslation";
import { Box, Stack, Typography } from "@mui/material";

export default function PageNotFound() {
  const { t } = useTranslation();
  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        pt: 4,
        textAlign: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          width: 250,
          height: 226,
        }}
      />
      <Typography variant="h2" mt={-2} mb={2}>
        404
      </Typography>
      <Typography
        variant="body2"
        sx={{
          maxWidth: 500,
        }}
      >
        {t("page-not-found")}
      </Typography>
    </Stack>
  );
}
